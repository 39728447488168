import { getUserInfo } from "../api/UserInfo";
import { useEffect, useState } from "react";
import { cypherMessage } from "../utilities/CypherMessage";

const LoginTreeLink = ({ user }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [link, setLink] = useState("");

	const getUserLink = async () => {
		if (!user) return;

		let userInfo = await getUserInfo(user.uid, user.accessToken);

		if (userInfo) {
			setLink(userInfo.treeUrl);

			if (userInfo.role === "admin") {
				localStorage.setItem("temp", JSON.stringify(userInfo));
			}
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getUserLink();
	}, [user]);

	return (
		<>
			{isLoading ? (
				<>loading...</>
			) : (
				<SmallLink show={link.length} url={link} />
			)}
		</>
	);
};

const SmallLink = ({ show, url }) => {
	return (
		<>
			{show ? (
				<a className="btn" href={url}>
					Open My Tree
				</a>
			) : (
				<></>
			)}
		</>
	);
};

export default LoginTreeLink;
