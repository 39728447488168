import axios from "axios";

import URLS from "../../api/url";

export const getUserInfo = async (userId, token) => {
	let userInfo = null;

	if (userId.length === 0) return "";

	const url = URLS.base_url + URLS.user.info + "?userId=" + userId;

	if (
		token === null ||
		typeof userId === "undefined" ||
		userId == undefined ||
		userId == "undefined"
	) {
		return "";
	}

	const headers = {
		headers: { authorization: `Bearer ${token}` },
	};

	await axios
		.get(url, headers)
		.then((res) => {
			const result = res.data;
			if (res.data.success) {
				userInfo = result.user;
				userInfo.treeUrl = buildTreeUrl(userInfo);
			} else {
				throw new Error("no url");
			}
		})
		.catch((error) => {
			if (error.code == "ERR_BAD_REQUEST") {
				//
			} else {
				return false;
			}
		});

	return userInfo;
};

const buildTreeUrl = (userInfo) => {
	return (
		"tree?id=" +
		userInfo.person_id +
		"&caste=" +
		userInfo.caste_id +
		"&lang=english"
	);
};
