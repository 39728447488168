import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const SignInPopup = () => {
	const [popupClass, setPopupClass] = useState("");

	useEffect(() => {
		let token = localStorage.getItem("accessToken");
		if (token === null) {
			setPopupClass("show");
		}
	}, []);

	return (
		<div className={"modal-box-container " + popupClass}>
			<div className="modal-box-wrapper block-must-signin">
				<h2>Please sign in to view this page</h2>
				<Link to="/login">Sign In</Link>
			</div>
		</div>
	);
};

export default SignInPopup;
